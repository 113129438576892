<template>
  <div v-if="!is_load">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item">
        <a href="/admin/news/lists">Quản lý Tin tức </a>
      </li>

      <li class="breadcrumb-item">Cập nhật Tin tức</li>
    </ol>

    <form method="post" @submit.prevent="mySubmit" id="sendform">
      <input type="hidden" :value="from.id" name="id" />
      <input type="hidden" :value="from.viewer" name="viewer" />
      <div class="container">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#home"
              >Tiếng Việt</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#menu1">English</a>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div id="home" class="container tab-pane active">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong>Thông tin chung </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="name"
                            >Tên <span class="text-danger">*</span></label
                          >
                          <input
                            class="form-control"
                            name="name"
                            type="text"
                            v-model="from.name"
                          />
                          <p class="text-danger">{{ error.name }}</p>
                        </div>
                      </div>

                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="picturefile"
                            >Hình ảnh đại diện<span class="text-danger">*</span>
                            (260px 170px) 2MB
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            name="picturefile"
                            class="form-control main_picture"
                            @change="onPicturefile"
                          />

                          <div class="view_picture_one" v-if="img_one != ''">
                            <img :src="img_one" style="max-width: 400px" />
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="parentId">
                            Danh mục cha<span class="text-danger"
                              >*</span
                            ></label
                          >
                          <select
                            class="form-control"
                            name="parentId"
                            v-model="from.parentId"
                            @change="changeCate"
                          >
                            <option value="">Tùy chọn</option>
                            <template v-for="elm in list_cate">
                              <option
                                :value="elm.id"
                                :key="elm.id"
                                v-if="
                                  elm.parentId == null &&
                                  isLogin.indexOf(elm.id) > -1
                                "
                              >
                                {{ elm.name }}
                              </option>
                            </template>
                          </select>

                          <p class="text-danger">{{ error.parentId }}</p>
                        </div>
                      </div>

                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="cateId"> Danh mục con</label>
                          <select
                            class="form-control"
                            name="cateId"
                            v-model="from.cateId"

                          >
                            <option value="" >Tùy chọn</option>
                            <template v-for="elm in list_cate_child">
                              <option
                                :value="elm.id"
                                :key="elm.id"
                                v-if="elm.parentId != null"
                              >
                                {{ elm.name }}
                              </option>
                            </template>
                          </select>
                        </div>
                      </div>


                      <!--  <div class="col-sm-10">
                        <div class="form-group">
                          <label for="departmentid">
                            Phòng ban
                            </label
                          >
                          <select class="form-control" name="departmentid" v-model="from.departmentId">
                            <option value="" selected>Tùy chọn</option>
                            <template v-for="dert in list_dertment" >
                              <option
                                :value="dert.id"
                                :key="dert.id"
                                
                              >
                                {{ dert.name }}
                              </option>
                            </template>
                          </select>

                        
                        </div>
                      </div> -->



                      <div class="col-sm-10">
                        <div class="form-group row">
                          <div class="col-sm-3">
                            <label for="name"> Trạng thái </label>
                          </div>
                          <div class="col-sm-9">
                            <select
                              name="Status"
                              class="form-control col-sm-3"
                              v-model="from.status"
                            >
                              <option value="1" >Hiện</option>
                              <option value="2">Ẩn</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-10">
                        <div class="form-group row">
                          <div class="col-sm-3">
                            <label for="name"> MENU</label>
                          </div>
                          <div class="col-sm-9">
                            <select
                              name="isMenu"
                              class="form-control col-sm-3"
                              v-model="from.isMenu"
                            >
                              <option value="1" selected>Hiện</option>
                              <option value="2">Ẩn</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="orderBy">Sắp sếp</label>
                          <input
                            class="form-control"
                            name="orderBy"
                            type="number"
                            v-model="from.orderBy"
                          />
                        </div>
                      </div>

                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="orderBy">Từ Ngày <small class="text-primary">(Năm - Tháng - Ngày) </small> </label>
                          <input
                            class="form-control"
                            name="submitdate"
                            type="text"
                            v-model="from.submitDate"
                            placeholder="YYYY-mm-dd"
                          />
                        </div>
                      </div>

                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="orderBy">Đến Ngày<small class="text-primary">(Năm - Tháng - Ngày) </small>  </label>
                          <input
                            class="form-control"
                            name="expirationdate"
                            type="text"
                            v-model="from.expirationDate"
                            placeholder="YYYY-mm-dd"
                          />
                        </div>
                      </div>

                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="orderBy"> Ngày cập nhật <small class="text-primary">(Năm - Tháng - Ngày) </small> </label>
                          <input
                            class="form-control"
                            name="datecreate"
                            type="text"
                            v-model="from.dateCreate"
                            placeholder="YYYY-mm-dd"
                          />
                        </div>
                      </div>

                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="description"> Mô tả </label>

                          <textarea
                            name="description"
                            class="form-control"
                            v-model="from.description"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-sm-10" v-if="is_view">
                        <div class="form-group">
                          <label for="content"> Nội dung </label>
                          <ckeditor
                            @namespaceloaded="onNamespaceLoaded"
                            :config="editorConfig"
                            v-model="content"
                            :editor-url="editorUrl"
                          ></ckeditor>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="menu1" class="container tab-pane fade">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong>Page </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="name"
                            >Name <span class="text-danger">*</span></label
                          >
                          <input
                            class="form-control"
                            name="name_EN"
                            type="text"
                            v-model="from.name_EN"
                          />
                          <p class="text-danger">{{ error.name_EN }}</p>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="description_EN">Description </label>

                          <textarea
                            name="description_EN"
                            class="form-control"
                            v-model="from.description_EN"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-sm-10" v-if="is_view">
                        <div class="form-group">
                          <label for="content_EN"> Content </label>
                          <ckeditor
                            @namespaceloaded="onNamespaceLoaded"
                            :config="editorConfig_EN"
                            v-model="content_EN"
                            :editor-url="editorUrl"
                          ></ckeditor>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-footer"
            style="width: 90%; position: fixed; bottom: 0"
          >
            <button
              type="submit"
              class="btn btn-sm btn-primary"
              v-if="!is_load"
            >
              <i class="fa fa-save"></i> Save
            </button>
            <h5 v-else>Loading...</h5>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div v-else>
    <SkeletonTheme>
      <Skeleton v-for="k in 10" height="50px" :key="k"></Skeleton>
    </SkeletonTheme>
  </div>
</template>

<script>
import axios from "axios";

import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";

//import { editorConfig } from "@/components/library/editorVideo.js";

// namespaceLoaded( ( CKEDITOR ) => {
//   CKEDITOR.plugins.addExternal( 'video', '/layout/ckeditor/video/plugin.js' );
// } );

export default {
  data() {
    return {
      is_load: true,
      list_dertment:null,
      from: {},
      id: this.$route.params.id,
      img_one: "",
      content: "",
      content_EN: "",
      status_img_one: false,
      error: {
        name: "",
        name_EN: "",
        picturefile: "",
        parentId: "",
      },
      editorConfig: window.editorConfigVideo,
      editorConfig_EN: window.editorConfigVideo_EN,
      list_cate: null,
      list_cate_main:null,
      list_cate_child:null,
      isLogin: null,

      editorUrl: "//cdn.ckeditor.com/4.14.1/full-all/ckeditor.js",
      is_view: false,
    };
  },
  components: {
    SkeletonTheme,
    Skeleton,
  },
  created() {
    let isLogin = this.$session.get("isLogin");
    this.isLogin = isLogin.permistions.split(",");
  },
  mounted() {
    this.Role=this.$session.get("Role");

    this.is_load = true;
    let p1= axios
      .get(
        window.my_api + "api/news/get-news-by-id?newsId=" + this.id,

        {
          headers: window.my_header,
        }
      )
      .then((res) => {
        if (res.status == 200) {
          this.from = res.data.data;

          console.log(this.from);
          if (
            res.data.data.picture &&
            res.data.data.picture != null &&
            res.data.data.picture != ""
          ) {
            this.img_one = window.my_url + "/" + res.data.data.picture;
          }
          this.content = res.data.data.content;
          this.content_EN = res.data.data.content_EN;

          


          
          if (this.from.submitDate == undefined ) {
             
                var dateObj = new Date();
                  var month = dateObj.getUTCMonth() + 1; //months from 1-12
              var day = dateObj.getUTCDate();
              var year = dateObj.getUTCFullYear();
              this.from.submitDate = year+"-"+month+"-"+day;
          }else{
           
              this.from.submitDate=this.from.submitDate.substring(0,10);
          }
         

           
          if (this.from.expirationDate == undefined) {
               var dateObj1 = new Date();
                 var month1 = dateObj1.getUTCMonth() + 1; //months from 1-12
                if (month1 < 11) {
                  month1 = month1 + 2;
                }
                var day1 = dateObj1.getUTCDate();
                var year1 = dateObj1.getUTCFullYear();
                this.from.expirationDate = year1+"-"+month1+"-"+day1;
             
           }else{
              this.from.expirationDate=this.from.expirationDate.substring(0,10);
           }
          

            

          if (this.from.dateCreate == undefined) {
                 var dateObj2 = new Date();
                 var month2 = dateObj2.getUTCMonth() + 1; //months from 1-12
                if (month2 < 11) {
                  month2 = month2 + 2;
                }
                var day2 = dateObj2.getUTCDate();
                var year2 = dateObj2.getUTCFullYear();
                this.from.dateCreate = year2+"-"+month2+"-"+day2;
          }else{
            this.from.dateCreate =this.from.dateCreate.substring(0,10);
          }
          
          
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      });

   let p2=  axios
      .get(window.my_api + "api/newscate/get-newscates", {
        headers: window.my_header,
      })
      .then((res) => {
        if (res.status == 200) {
        
          this.list_cate_child=this.list_cate_main=this.list_cate = res.data.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });


       let p3= axios
          .get(window.my_api + "api/department/get-departments", {
            headers: window.my_header,
          })
          .then((res) => {
            if (res.status == 200) {
              this.list_dertment = res.data.data;
              
            }
          })
          .catch((err) => {
            console.log(err);
          });

      Promise.all([p1,p2,p3]).then(()=>{
          this.is_load = false;
          this.is_view = true;
      });
  },

  methods: {
    changeCate(){
        this.from.cateId="";
        this.list_cate_child=this.list_cate_main.filter(n=>n.parentId==this.from.parentId);
    },
    onNamespaceLoaded(CKEDITOR) {
    
      CKEDITOR.plugins.addExternal("video", "/layout/ckeditor/video/plugin.js");
      CKEDITOR.plugins.addExternal("html5audio", "/layout/ckeditor/html5audio/plugin.js");
    },
    onPicturefile(e) {
      let _this = this;
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        _this.img_one = reader.result;
      };
      reader.readAsDataURL(file);
    },
    alertError() {
      let tt = "";
      for (let i = 0; i < Object.values(this.error).length; i++) {
        if (Object.values(this.error)[i]) {
          tt =
            tt +
            "<ul style='text-align:left;'><li>" +
            Object.values(this.error)[i] +
            "</li></ul>";
        }
      }
      return tt;
    },
    mySubmit: function () {
      let myForm = document.getElementById("sendform");
      let customer=this.$session.get("Customer");
      console.log(customer);
      let formData = new FormData(myForm);

      for (var pair of formData) {
        if (pair[0] == "name") {
          if (pair[1] == "") {
            this.error.name = "Vui lòng nhập tên.";
          } else {
            this.error.name = "";
          }
        }
        if (pair[0] == "name_EN") {
          if (pair[1] == "") {
            this.error.name_EN = "Vui lòng nhập tên tiếng anh";
          } else {
            this.error.name_EN = "";
          }
        }

        if (pair[0] == "parentId") {
          if (pair[1] == "") {
            this.error.parentId = "Vui lòng chọn danh mục cha";
          } else {
            this.error.parentId = "";
          }
        }
        if (pair[0] == "picturefile") {
          if (pair[1].name == "") {
            this.status_img_one = true;
          }
        }
      }

      if (
        this.error.name == "" &&
        this.error.name_EN == "" &&
        this.error.parentId == ""
      ) {
        if (this.status_img_one) {
          formData.delete("picturefile");
          formData.append("picture", this.from.picture);
        }

        formData.append("content", this.content);
        formData.append("departmentid",this.Role[0].departmentId );
        formData.append("content_EN", this.content_EN);

       // formData.append("userid",customer.id);
       console.log(window.my_header);
        axios
          .post(
            window.my_api + "api/news/create-or-update-news",
            formData,

            {
              headers: window.my_header,
            }
          )
          .then((res) => {
            if (res.status == 200) {
              if(res.data.successed){
                 

alert("Sửa thành công");
               }else{
                   
                   alert("Tên bài viết đã tồn tại.");
                  console.log(res.data);
               }
             
            } else {
              alert("Thêm không thành công, vui lòng liên hệ admin");
            }
          });
      } else {
        console.log(this.alertError());

alert("Vui lòng nhập đầy đủ");
      }
    },
  },
};
</script>